import {
  Box,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
} from "@material-ui/core"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import ClearAllIcon from "@material-ui/icons/ClearAll"
import React, { useMemo, useState } from "react"
import Layout from "../components/layout"
import { myContext } from "../../provider"
import { Configure, connectHits, InstantSearch } from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"
import ResultItem from "../components/search/resultItem"
import { Button } from "gatsby-theme-material-ui"
import getFilterString from "../utils/getFilterString"
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component"

const CustomHits = connectHits(({ hits, bookmarks }) =>
  hits.map(hit => (
    <ResultItem key={hit.ACCNO} hit={hit} bookmarks={bookmarks} />
  ))
)

const BookmarkPage = () => {
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

  const [confirmOpen, setConfirmOpen] = useState(false)
  const handleClear = clearall => {
    clearall()
    setConfirmOpen(false)
  }
  
  const [saveOpen, setSaveOpen] = useState(false)

  return (
    <Layout>
      <myContext.Consumer>
        {context => {
          const arrayBookmarks = [...context.bookmarks]
          const filterString = getFilterString(arrayBookmarks)
          const UrlParams = encodeURIComponent(JSON.stringify(arrayBookmarks))

          return (
            <Container maxWidth="md">
              <Box py={4}>
                <Box mb={2}>
                  <Typography variant="h1">Bookmarks</Typography>
                  <Button
                    onClick={() => setConfirmOpen(true)}
                    disabled={!context.bookmarks.size}
                    endIcon={<ClearAllIcon />}
                  >
                    Clear all
                  </Button>
                  <Dialog
                    open={confirmOpen}
                    onClose={() => setConfirmOpen(false)}
                  >
                    <DialogTitle>
                      {"Are you sure you want to remove all your bookmarks?"}
                    </DialogTitle>
                    <DialogActions>
                      <Button
                        color="primary"
                        onClick={() => handleClear(context.clearall)}
                      >
                        Yes
                      </Button>
                      <Button
                        onClick={() => setConfirmOpen(false)}
                        color="primary"
                      >
                        No
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Button
                    onClick={() => setSaveOpen(true)}
                    endIcon={<SaveAltIcon />}
                    style={{ marginLeft: 8 }}
                    disabled={!context.bookmarks.size}
                  >
                    Save
                  </Button>
                  <Dialog
                    fullWidth
                    open={saveOpen}
                    onClose={() => setSaveOpen(false)}
                  >
                    <DialogTitle>Save for later</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Your current bookmarks will disappear when you close
                        your browser window. To view these bookmarked items
                        later, save and use the URL below:
                      </DialogContentText>
                      <TextField
                        fullWidth
                        defaultValue={`https://sarrc-dev.netlify.app/load-items?accnos=${UrlParams}`}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <CopyToClipboard
                        text={`https://sarrc-dev.netlify.app/load-items?accnos=${UrlParams}`}
                      >
                        <Button color="primary" endIcon={<FileCopyIcon />}>
                          Copy to clipboard
                        </Button>
                      </CopyToClipboard>
                    </DialogActions>
                  </Dialog>
                </Box>
                {context.bookmarks.size ? (
                  <InstantSearch
                    searchClient={searchClient}
                    indexName={`sarrc_catalog`}
                  >
                    <Configure filters={filterString} />
                    <CustomHits />
                  </InstantSearch>
                ) : (
                  <Card>
                    <CardContent>
                      You haven't bookmarked any items yet!
                    </CardContent>
                  </Card>
                )}
              </Box>
            </Container>
          )
        }}
      </myContext.Consumer>
    </Layout>
  )
}

export default BookmarkPage
