// Utility to get Algolia refinement string from array of AccNos

const getFilterString = arrayBookmarks => {
  let filterString = ""
  arrayBookmarks &&
    arrayBookmarks.forEach((accNo, index) => {
      filterString += index === 0 ? `ACCNO:${accNo}` : ` OR ACCNO:${accNo}`
    })

  return filterString
}

export default getFilterString
